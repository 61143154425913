import.meta.glob([
    '../images/**',
]);

import $ from 'jquery';
window.jQuery = window.$ = $;

import * as bootstrap from 'bootstrap';

// Set CSRF token
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('[name="_token"]').val(),
        'X-GLOBAL-LANGUAGE': $('html').attr('lang')
    }
});